import Link from "next/link"
import styles from "./hero.module.css"
import classNames from "classnames"

export function Article({ item }) {
  const { meta } = item
  const { dateline } = meta
  let time = dateline.created

  if (dateline.readTime > 0) {
    time = `${time} • ${dateline.readTime} min att läsa`
  }

  return (
    <Link
      href={item?.url}
      prefetch={false}
      className={classNames(styles.article)}>
      <h2>{item.title}</h2>
      <span className={styles.meta}>
        {meta.byline && <span className="block">Av: {meta.byline}</span>}
        {time}
      </span>
    </Link>
  )
}
